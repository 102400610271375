import React from 'react'

export default function Right() {
    return (

        <section class="text-blueGray-700 bg-gray-100">
        <div class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row lg:px-28 container mx-auto">
          <div class="flex flex-col items-start mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 md:mb-0">
            <h1 class="mb-8 text-2xl font-black text-black tracking-tighter text-black md:text-5xl title-font"> Medium length display headline. </h1>
            <p class="mb-8 text-base leading-relaxed text-left text-black"> Deploy your mvp in minutes, not days. WT offers you a a wide selection swapable sections for your landing page. </p>
          </div>
          <div class="w-full lg:w-5/6 lg:max-w-lg md:w-1/2">
            <img class="object-cover object-center rounded-lg " alt="hero" src="https://dummyimage.com/720x600/F3F4F7/8693ac"/>
          </div>
        </div>
      </section>
    
    )
}
