import React from 'react'

export default function Navbar() {
    return (
        <div class=" container mx-auto items-center ">
        <div class="text-blueGray-700 rounded-lg">
          <div class="flex flex-col flex-wrap p-5 mx-auto md:items-center md:flex-row">
            <a href="/" class="pr-2 lg:pr-8 lg:px-6 focus:outline-none">
              <div class="inline-flex items-center">
                <h2 class="block quicksand p-2 text-xl font-black tracking-tighter text-black transition duration-500 ease-in-out transform cursor-pointer hover:text-blueGray-500 lg:text-x lg:mr-8"><span role="img" aria-label="lightning">⚡️</span> hypercode</h2>
              </div>
            </a>
            <nav class="flex flex-wrap items-center justify-center text-base lg:mr-auto">
              <ul class="items-center inline-block list-none lg:inline-flex">
                <li>
                  <a href="/" class="px-4 py-1 mr-1 text-base text-blueGray-500 transition duration-500 ease-in-out transform rounded-md focus:shadow-outline focus:outline-none hover:text-gray-500 ">Why us?</a>
                </li>
                <li>
                  <a href="/" class="px-4 py-1 mr-1 text-base text-blueGray-500 transition duration-500 ease-in-out transform rounded-md focus:shadow-outline focus:outline-none hover:text-gray-500">What you'll learn</a>
                </li>
                <li>
                  <a href="/" class="px-4 py-1 mr-1 text-base text-blueGray-500 transition duration-500 ease-in-out transform rounded-md focus:shadow-outline focus:outline-none hover:text-gray-500">Schedule</a>
                </li>
                <li>
                  <a href="/" class="px-4 py-1 mr-1 text-base text-blueGray-500 transition duration-500 ease-in-out transform rounded-md focus:shadow-outline focus:outline-none hover:text-gray-500">Tuition</a>
                </li>
                <li>
                  <a href="/" class="px-4 py-1 mr-1 text-base text-blueGray-500 transition duration-500 ease-in-out transform rounded-md focus:shadow-outline focus:outline-none hover:text-gray-500">FAQs</a>
                </li>
              </ul>
            </nav>
            <button class="w-auto px-8 py-2 my-2 text-base font-medium text-white transition duration-500 ease-in-out transform bg-black border-blue-600 rounded-md focus:shadow-outline focus:outline-none hover:bg-gray-700 ">Apply</button>
          </div>
        </div>
      </div>
    )
}
