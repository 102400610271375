import React from 'react';
import CTA from './components/CTA';
import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Pricing from './components/Pricing';
import Right from './components/Right';
import './styles/output.css'


function App() {
  return (
    <div>
    <Navbar/>
    <Header/>
    <Right/>
    <Right/>
    <Right/>
    <Pricing/>
    <CTA/>
    <Footer/>

    </div>
  );
}

export default App;
