import React from 'react'

export default function Header() {
    return (
          <section class="text-blueGray-700 my-10">
            <div class="container flex flex-col px-5 py-8 mx-auto lg:items-center">
              <div class="flex flex-col w-full text-left lg:text-center">
                <h1 class="mx-auto mb-6 text-4xl font-extrabold leading-none tracking-tighter text-black lg:w-4/5 sm:text-7xl title-font">Become a Data Science Intern as a highschool student</h1>
                <p class="mx-auto text-base font-medium leading-relaxed text-blueGray-700 lg:w-2/3">You're about to launch soon and must be 100% focused on your product. Don't loose precious days designing, coding the landing page and testing . </p>
                <div class="flex items-start w-full mt-12 lg:mx-auto lg:justify-center lg:w-1/2"></div>
            </div>
        </div>
          </section>
    )
}
