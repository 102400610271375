import React from 'react'

export default function Pricing() {
    return (

        <section class="text-blueGray-700 container mx-auto">
            <div class="flex flex-col w-full mx-auto my-20 text-left lg:w-2/3 lg:text-center">
                <h1 class="mb-6 text-2xl font-semibold tracking-tighter text-black sm:text-5xl title-font"> A medium length headline. </h1>
                <p class="mx-auto text-base font-medium leading-relaxed text-blueGray-700 lg:w-2/3"> You're about to launch soon and must be 100% focused on your product. Don't loose precious days designing, coding the landing page and testing . </p>
            </div>
            <div class="container items-center px-5 py-12 lg:px-20">
                <div class="flex flex-wrap items-center justify-center w-full gap-4">
                    <div class="w-full xl:w-1/4 md:w-2/6">
                        <div class="relative flex flex-col h-full p-8 transition duration-500 ease-in-out transform bg-white border rounded-lg shadow-xl">
                            <h2 class="mb-4 text-sm font-medium tracking-widest text-black uppercase title-font"> Sponsorships </h2>
                            <p class="flex items-center mb-8 text-base font-medium leading-relaxed text-blueGray-700"> All the basics for starting a new biz.. </p>
                            <strong class="flex items-end text-3xl font-black leading-none text-black lg:text-4xl ">
                                <span>$10 </span>
                            </strong>
                            <p class="flex items-center mt-8 mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Newsletter's Top.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white bg-blueGray-500 rounded-full">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </span>1 Newsletter Issue.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white bg-blueGray-500 rounded-full">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </span>1.6k+ Readers.
                            </p>
                            <button class="w-full px-4 py-2 mt-6 text-base font-medium text-blue-600 transition duration-500 ease-in-out transform bg-blue-100 rounded-lg hover:bg-blue-300 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">Buy Now </button>
                        </div>
                    </div>
                    <div class="w-full xl:w-1/4 md:w-2/6">
                        <div class="relative flex flex-col h-full p-8 transition duration-500 ease-in-out transform bg-white border rounded-lg shadow-xl ">
                            <h2 class="mb-4 text-sm font-medium tracking-widest text-black uppercase title-font"> Sponsorships </h2>
                            <p class="flex items-center mb-8 text-base font-medium leading-relaxed text-blueGray-700"> All the basics for starting a new biz.. </p>
                            <strong class="flex items-end text-3xl font-black leading-none text-black lg:text-4xl ">
                                <span>$50 </span>
                            </strong>
                            <p class="flex items-center mt-8 mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Newsletter's Top.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>1 Newsletter Issue.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>1.6k+ Readers.
                            </p>
                            <button class="w-full px-4 py-2 mx-auto mt-6 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md items-centerw-full focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-700 ">Buy Now </button>
                        </div>
                    </div>
                    <div class="w-full xl:w-1/4 md:w-2/6">
                        <div class="relative flex flex-col h-full p-8 transition duration-500 ease-in-out transform bg-white border rounded-lg shadow-xl">
                            <h2 class="mb-4 text-sm font-medium tracking-widest text-black uppercase title-font"> Sponsorships </h2>
                            <p class="flex items-center mb-8 text-base font-medium leading-relaxed text-blueGray-700"> All the basics for starting a new biz.. </p>
                            <strong class="flex items-end text-3xl font-black leading-none text-black lg:text-4xl ">
                                <span>$10 </span>
                            </strong>
                            <p class="flex items-center mt-8 mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>Newsletter's Top.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white bg-blueGray-500 rounded-full">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </span>1 Newsletter Issue.
                            </p>
                            <p class="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                                <span class="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white bg-blueGray-500 rounded-full">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </span>1.6k+ Readers.
                            </p>
                            <button class="w-full px-4 py-2 mt-6 text-base font-medium text-blue-600 transition duration-500 ease-in-out transform bg-blue-100 rounded-lg hover:bg-blue-300 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">Buy Now </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
