import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css'



ReactDOM.render(
  <React.StrictMode>
    <head>
    <link rel="preconnect" href="https://fonts.googleapis.com"></link>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap" rel="stylesheet"></link>
    </head>
    <body>
    <App />
    </body>
  </React.StrictMode>,
  document.getElementById('root')
);

