import React from 'react'

export default function CTA() {
    return (
<section class="w-full">
  <div class="w-full px-4 py-20 mx-auto text-left md:text-center md:w-3/4 lg:w-2/4">
    <p class="mb-2 text-base font-semibold text-purple-700">Start your free trial now</p>
    <h2 class="mb-6 text-3xl font-extrabold tracking-tight md:text-4xl md:mb-6 md:leading-tight">Sign up for a free account to organize your customer feedback.</h2>
  </div>
</section>

    )
}
